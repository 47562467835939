<template>
  <div id="test">
    <div v-if="local">
      <b-form>
        <b-input type="text" v-model="code" placeholder="Enter test name"></b-input>
      </b-form>
      <div v-if="code === 'pathalias'">
        <b-form @submit="fetchAlias">
          <b-input type="text" v-model="pathalias" placeholder="Pathalias"></b-input>
        </b-form>
        <div>
          <h2>path alias</h2>
          <pre>{{ pathalias }}</pre>
        </div>
        <div>
          <h2>resolved path alias</h2>
          <pre>{{ pathaliasResolved }}</pre>
        </div>
      </div>
    </div>
    <div v-if="!local">
      <b-button variant="danger" disabled>Dieses Feature ist nur lokal verfügbar.</b-button>
    </div>
  </div>
</template>

<script>
import { EtagenDrupalApi } from "@/etagen-api";

export default {
  name: "Test",
  data() {
    return {
      code: "",
      pathalias: "",
      pathaliasResolved: "",
      local: false
    };
  },
  methods: {
    fetchAlias(event) {
      //console.log(event);
      event.preventDefault();

      let alias = this.pathalias;
      alias = alias.replace(/\//g, "|");

      if (alias.match(/taxonomy\|term/gi)) {
        alias = alias.replace(/taxonomy\|term/g, "taxonomy_term");
      }
      EtagenDrupalApi.resolveAlias(alias)
        .then(result => {
          this.pathaliasResolved = result;
        })
        .catch(error => {
          this.pathaliasResolved = error;
        });
    }
  },
  created() {
    if (process.env.NODE_ENV === "development") {
      this.local = true;
    }
  }
};
</script>

<style scoped lang="scss">
pre {
  color: white !important;
}
</style>
